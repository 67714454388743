import { BrowserRouter,  Route, Routes } from "react-router-dom";

import AllRotues from "./allRoutes";
import { Fragment } from "react";
import MainlLayout from "../layout/mainLayout.jsx";
import NotFound from "../pages/message/notFound.jsx";

function MainWrapper({ route, children }) {
  const PrivateWrapper = Fragment;


  if (route.layout === "mainLayout") {
    return (
        <PrivateWrapper>
          <MainlLayout>{children}</MainlLayout>
        </PrivateWrapper>
    );
  }  else {
    return (
        <PrivateWrapper>{children}</PrivateWrapper>
    );
  }

}

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {AllRotues.map((route) => {
          return (
            <Route
              key={route.id}
              path={route.path}
              element={
                <MainWrapper route={route}>
                  <route.component />
                </MainWrapper>
              }
            />
            
          );
        })}
         <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
