import React from "react";
import Banner from "../component/slider/slider.jsx";
import Quotations from "../component/quotations/quotations.jsx";

import About from "../component/index/about.jsx";
import BestProduct from "../component/index/bestProduct.jsx";
import Testimonial from "../component//index/testimonial.jsx";

export default function Home() {
  return (
    <div className="flex justify-center flex-col pb-5">
      {/* slider */}

      <div className="my-3 rounded-md flex justify-center">
        <Banner />
      </div>

      {/* quotations */}
      <div className=" flex justify-center items-center">
        <div className=" flex gap-5 flex-col my-3 w-[90%]  md:w-[75%]  ">
          <div className="flex justify-center flex-row  bg-white ">
            <Quotations />
          </div>
        </div>
      </div>
      {/* about */}
      <div className=" flex justify-center items-center bg-primary text-white">
        <div className=" flex gap-5 flex-col my-3 w-[90%]  md:w-[75%]  ">
          <div className="flex justify-center">
            <About />
          </div>
        </div>
      </div>

      {/* best product */}
      <div className=" flex justify-center items-center ">
        <div className=" flex gap-5 flex-col my-3 w-[90%]  md:w-[75%]  ">
          <div className="flex justify-center">
            <BestProduct />
          </div>
        </div>
      </div>
      {/* testimonial */}
      <div className="flex justify-center bg-primary text-white my-5">
        <Testimonial />
      </div>

      {/* location */}
      <div className=" flex  justify-center  my-3">
        <div className=" w-[95%]">
          <div className=" flex flex-col  justify-center items-center ">
            <h1 className="text-2xl font-bold mt-2 md:text-4xl">Find Us</h1>
            <p className="text-sm text-center mb-5 md:text-2xl ">
              Visit us at our cozy cafe, conveniently located in the heart of
              the city.
            </p>
          </div>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3516.0372971258566!2d83.95891997629502!3d28.206179403312508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399595003c12bc9b%3A0x4168db8892cb9c14!2sDozy%20kooki!5e0!3m2!1sen!2snp!4v1715932249362!5m2!1sen!2snp"
            style={{ border: 0 }}
            loading="lazy"
            className=" w-full h-[50vh]"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
