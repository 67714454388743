import { LuCoffee,LuCakeSlice   } from "react-icons/lu";
import { GiSlicedBread } from "react-icons/gi";
import { BsCookie } from "react-icons/bs";

export default function Quotations() {
  const quotations = [
    {
      id: 1,
      icon: LuCoffee,
      title: "Coffee",
      description: "Organic coffee beans.",
    },
    {
      id: 2,
      icon: GiSlicedBread,
      title: "Bread",
      description: "Freshly baked bread from the best bakeries.",
    },
    {
      id: 3,
      title: "Cookies",
      icon: BsCookie,
      description: "Delicious cookies for all occasions.",
    },
    {
      id: 4,
      title: "Cakes",
      icon: LuCakeSlice,
      description: "Custom cakes for all occasions.",
    },
  ];

  return (
    <div className="  ">
      <div className=" grid grid-cols-1  gap-2 md:gap-5 sm:grid-cols-2 md:grid-cols-4">
        {quotations.map((quotation) => (
          <div
            className=" flex gap-2 p-2  rounded-xl px- border-2 border-primary  "
            key={quotation.id}
          >
            <div className="icon flex justify-center items-center">
              {quotation.icon ? (
                <quotation.icon className=" w-[50px] text-4xl" />
              ) : (
                <img
                  src={quotation.img}
                  alt="americano.png"
                  className="h-20 w-20 object-cover rounded-3xl"
                />
              )}
            </div>
            <div className="">
              <h1 className="text-xl font-bold">{quotation.title}</h1>
              <p className=" text-[0.5rem]">{quotation.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
