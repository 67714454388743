import Home from '../pages/index';
import Test from '../pages/test';
import Menu from '../pages/menu';

const allRoute = [
  {
    id: "index",
    path: "/",
    component: Home,
    layout: "mainLayout",
  },
  {
    id: "about",
    path: "/about",
    component: Test,
    layout: "",
  },
  {
    id:"menu",
    path:"/menu",
    component:Menu,
    layout:"mainLayout"
  }
];

export default allRoute;
