import React, { useState } from "react";
import Testimonial from "../component/testimonial/testimonial";



export default function Test() {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  const menuData = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "About",
      link: "/about",
    },
    {
      title: "Contact",
      link: "##",
    },
  ];
  return (
    <div className="">
      <div className=" ">
        <header className="bg-primary bg-opacity-50 top-0 fixed w-full z-50"
            // style={{
            //     backgroundImage: "url(./img/banner/banner1.jpg)",
            //     backgroundSize: "cover",
            //     backgroundPosition: "center",
            //     backgroundRepeat: "no-repeat",
            //     }}
        >
          {/*  desktop view */}
          <div className="hidden md:flex justify-between items-center py-2 mx-40 ">
            <div className=" flex justify-center items-center gap-2  ">
              <img src="./img/logo/logo.png" alt="" className=" w-16" />
              <h1 className="text-white text-2xl font-bold">Dozy Kooki</h1>
            </div>
            <div>
              <ul className="flex space-x-5">
                {menuData.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.link}
                      className="text-white hover:text-slate-500"
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/*  mobile view */}
          <div className="md:hidden flex flex-col justify-between items-center">
            <div className="text-white text-2xl font-bold flex justify-between w-full bg-primary bg-opacity-50 py-2 px-7">
              <div className=" flex justify-center items-center gap-2 ">
                <img src="./img/logo/logo.png" alt="" className="w-12" />
                Dozy Kooki
              </div>
              <div
                onClick={handleMenu}
                className=" flex justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 5.25Zm0 4.5A.75.75 0 0 1 3.75 9h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 9.75Zm0 4.5a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Zm0 4.5a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>

            <div
              className={`bg-primary  text-white z-50 fixed top-0 right-0 h-full transition-transform duration-500 ease-in-out w-60 ${
                showMenu
                  ? "transform translate-x-0"
                  : "transform translate-x-full"
              }`}
              onClick={handleMenu}
            >
              <ul className="flex flex-col p-3 w-full items-center gap-2 ">
                <div className=" font-bold text-3xl p-2">Dozy Kooki</div>
                {menuData.map((item, index) => (
                  <li key={index} className=" w-full    ">
                    <a
                      href={item.link}
                      className=" block hover:bg-slate-500 w-full p-2 rounded-md "
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </header>
        <div className="">
            <img src="./img/banner/banner1.jpg" alt="" 
            className="w-full h-[50vh] md:h-[90vh] object-cover"
            />
        </div>

        <body>

            {/* Testimonial */}
            <div className=" flex justify-center mt-10 ">
                <Testimonial  />
            </div>
        </body>
      </div>
    </div>
  );
}
