import React from "react";
import { FaInstagram } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

export default function Header() {
  const [showMenu, setShowMenu] = React.useState(false);

  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  const menuData = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Menu",
      link: "/menu",
    },
    {
      title: "About",
      link: "/about",
    },
    {
      title: "Contact",
      link: "##",
    },
  ];

  return (
    <header className="bg-primary">
      {/*  desktop view */}
      <div className="hidden md:flex justify-between items-center py-2 mx-40 ">
        <div className=" flex justify-center items-center gap-2  ">
          <img src="./img/logo/logo.png" alt="" className=" w-16" />
          <h1 className="text-white text-2xl font-bold">Dozy Kooki</h1>
        </div>
        <div>
          <ul className="flex space-x-2">
            {menuData.map((item, index) => (
              <li
                key={index}
                className=" border border-white text-white  px-3 py-1 rounded-2xl hover:bg-white hover:text-primary "
              >
                <a href={item.link} className="font-bold">
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/*  mobile view */}
      <div className="md:hidden flex flex-col justify-between items-center">
        <div className="text-white text-2xl font-bold flex justify-between w-full bg-primary py-2 px-7">
          <div className=" flex justify-center items-center gap-2 ">
            <img src="./img/logo/logo.png" alt="" className="w-12" />
            Dozy Kooki
          </div>
          <div
            onClick={handleMenu}
            className=" flex justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M3 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 5.25Zm0 4.5A.75.75 0 0 1 3.75 9h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 9.75Zm0 4.5a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Zm0 4.5a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>

        <div
          className={`bg-primary text-white z-50 fixed top-0 right-0 h-full transition-transform duration-500 ease-in-out w-60 ${
            showMenu ? "transform translate-x-0" : "transform translate-x-full"
          }`}
          onClick={handleMenu}
        >
          <ul className="flex flex-col p-3 w-full items-center gap-2 ">
            <div className=" font-bold text-3xl p-2">Dozy Kooki</div>
            {menuData.map((item, index) => (
              <li key={index} className=" w-full    ">
                <a
                  href={item.link}
                  className=" block hover:bg-slate-500 w-full p-2 rounded-md "
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <div className=" p-3 flex justify-center  border-t gap-5">
            <a href="##">
              <FaInstagram className="w-7 h-7" />
            </a>
            <a href="##">
              <BsTwitterX className="w-7 h-7" />
            </a>
            <a href="##">
              <FaFacebookF className="w-7 h-7" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
