import React, { useState, useEffect } from "react";
import MenuSlider from "./menuSlider.jsx";

// Importing menu data
import { MenuTypes, Menuheadings, MenuData } from "../../data/menu/main.js";

export default function App() {
  const [active, setActive] = useState(1);
  const [selectedMenuType, setSelectedMenuType] = useState(1);
  const [menuData, setMenuData] = useState([]);

  const [additional, setAdditional] = useState([]);

  const [selectedMenuTypesMenuheadings, setSelSelectedMenuTypesMenuheadings] =
    useState([]);

    useEffect(() => {
      // Filter menu headings based on selected menu type
      const filteredMenuheadings = Menuheadings.filter(
        (item) => item.menuTypeId === selectedMenuType
      );
      setSelSelectedMenuTypesMenuheadings(filteredMenuheadings);
    
      // Set the active heading to the first item in the filtered menu headings array
      if (filteredMenuheadings.length > 0) {
        setActive(filteredMenuheadings[0].id);
      }
    
      // Filter additional data based on selected menu type and "Additional" heading
      const additionalHeadingId = Menuheadings.find(
        (item) => item.menuTypeId === selectedMenuType && item.value === "additional"
      )?.id;
    
      const filteredAdditional = MenuData.filter(
        (item) => item.menuTypes === selectedMenuType && item.Menuheadings === additionalHeadingId
      );
      setAdditional(filteredAdditional);
    }, [selectedMenuType])

  useEffect(() => {
    // Filter menu data based on selected menu type and active heading
    const filteredMenuData = MenuData.filter(
      (item) =>
        item.menuTypes === selectedMenuType && item.Menuheadings === active
    );

    setMenuData(filteredMenuData);
  }, [selectedMenuType, active]);
  
  return (
    <div className="w-[90%] md:w-[80%] mx-auto mt-5 flex flex-col gap-5">
      <div className=" py-5 border ">
        <h1 className="flex justify-center items-center font-extrabold text-2xl">
          Menu Type
        </h1>
        {/* Menu Type Slider */}
        <MenuSlider
          sliderData={MenuTypes}
          active={selectedMenuType}
          setActive={setSelectedMenuType}
        />
      </div>

      <div className=" py-5 border ">
        <h1 className="flex justify-center items-center font-extrabold text-2xl">
          Menu Heading
        </h1>
        {/* Menu Data Slider */}
        {selectedMenuTypesMenuheadings.length > 0 && (
          <MenuSlider
            sliderData={selectedMenuTypesMenuheadings}
            active={active}
            setActive={setActive}
          />
        )}
      </div>

      {/* Menu Items */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-4 mt-5">
        {menuData.map((item) => (
          <div
            className="flex justify-between gap-2 p-2 bg-primary rounded-xl text-white"
            key={item.id}
          >
             <div className=" w-4/5">
              <h1 className="font-bold capitalize">{item.title}</h1>
              <p className="text-xs">{item.description}</p>
            </div>
            <div className="border-l border-white pl-3 w-1/5">
              <p className="text-md capitalize font-bold">
                Rs.{" "}
                {typeof item.price === "number"
                  ? item.price % 1 === 0
                    ? item.price
                    : item.price.toFixed(2)
                  : item.price}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Additional Information */}
      <div className="p-5 border bg-primary text-white rounded-xl">
        <h1 className="font-extrabold text-2xl">Additional Add On</h1>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-4 mt-5">
          {additional.map((item) => (
            <div
              className="flex justify-between gap-2 p-2  rounded-xl border border-white"
              key={item.id}
            >
              <div className=" w-4/5">
                <h1 className="font-bold capitalize">{item.title}</h1>
                <p className="text-xs">{item.description}</p>
              </div>
              <div className="border-l border-white pl-3 w-1/5">
                <p className="text-md capitalize font-bold">
                  Rs.{" "}
                  {typeof item.price === "number"
                    ? item.price % 1 === 0
                      ? item.price
                      : item.price.toFixed(2)
                    : item.price}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
