const SpecialDrinks = [
  {
    id: 1,
    name: "Blueberry Shake",
    img: "./img/drinks/BlueberryShake.jpeg",
    description:
      "Blueberry shake is a delicious and creamy shake made with blueberry, milk and ice cream.",
  },
  {
    id: 2,
    name: "Kitkat Shake",
    img: "./img/drinks/KitkatShake.jpeg",
    description:
      "Kitkat shake is a delicious and creamy shake made with kitkat chocolate bars, milk and ice cream.",
  },
  {
    id: 3,
    name: "Espresso Affogato",
    img: "./img/drinks/EspressoAffogato.jpg",
    description:
      "Espresso Affogato is a coffee drink that is made with espresso and ice cream.",
  },
  {
    id: 4,
    name: "Flavour Latte",
    img: "./img/drinks/FlavourLatte.jpg",
    description:
      "Flavour Latte is a coffee drink that is made with espresso, steamed milk, and milk foam with different flavours.",
  },
];

function truncateText(text, wordLimit) {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
}

const BestProduct = () => {
  return (
    <div className=" flex flex-col justify-center items-center gap-10 ">
      {/* best drinks */}
      <div className="w-full flex justify-center items-center flex-col">
        <h1 className="text-2xl font-bold mt-2 md:text-4xl">
          Specialty Drinks
        </h1>
        <p className=" text-center mb-5 text-sm md:my-2 md:mb-4">
          Sip on our expertly crafted coffee, tea, and signature beverages.
        </p>

        <div className="grid grid-cols-2 gap-3 md:gap-8 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 w-full">
          {SpecialDrinks.map((item) => (
            <div
              className="flex gap-2 p-2 rounded-xl border border-primary flex-col"
              key={item.id}
            >
              <div className="icon">
                <img
                  src={item.img}
                  alt={item.name}
                  className="h-[150px] md:h-[350px] w-full object-cover rounded-xl"
                />
              </div>
              <div className=" flex justify-between flex-col">
                <h1 className="text-md md:text-2xl font-bold">{item.name}</h1>
                <p className="text-[0.5rem]  md:text-xs">
                  {truncateText(item.description, 10)}{" "}
                  {/* Adjust the word limit as needed */}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* best bakery */}
      <div className="w-full flex justify-center items-center flex-col">
      <h1 className="text-2xl font-bold mt-2 md:text-4xl">
          Delectable Pastries
        </h1>

        <p className=" text-center mb-5 text-sm md:my-2 md:mb-4">
          Indulge in our freshly baked selection of croissants, muffins, and
          more.
        </p>

        <div className="grid grid-cols-2 gap-3 md:gap-8 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 w-full">
          {SpecialDrinks.map((item) => (
            <div
              className="flex gap-2 p-2 rounded-xl border border-primary flex-col"
              key={item.id}
            >
              <div className="icon">
                <img
                  src={item.img}
                  alt={item.name}
                  className="h-[150px] md:h-[350px] w-full object-cover rounded-xl"
                />
              </div>
              <div className=" flex justify-between flex-col">
                <h1 className="text-md md:text-2xl font-bold">{item.name}</h1>
                <p className="text-[0.5rem]  md:text-xs">
                  {truncateText(item.description, 10)}{" "}
                  {/* Adjust the word limit as needed */}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BestProduct;
