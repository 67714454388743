import React from "react";

import Header from "../component/mainLayout/header";
import Footer from "../component/mainLayout/footer";

export default function MainLayout({ children }) {
  return (
    <div className="">
      <div className=" ">
        <Header />
        {children}
        <Footer />
      </div>
    </div>
  );
}
