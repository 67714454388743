import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
// slider data
const sliderData = [
  {
    img: "./img/banner/banner1.jpg",
  },
  {
    img: "./img/banner/banner2.jpg",
  },
  {
    img: "./img/banner/banner3.jpg",
  },
];

export default function App() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        className="mySwiper md:h-[80vh]"
      >
        <div className="flex justify-center items-center pt-5">
          {sliderData.map((item, index) => (
            <SwiperSlide key={index} 
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <div className="flex justify-center border-none w-[95%] ">
                <img
                  src={item.img}
                  alt="americano.png"
                  className=" w-[100%] h-[200px] md:h-[80vh] object-cover rounded-3xl"
                />
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </>
  );
}
