import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// Import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";

// Testimonial data
import testimonialData from "../../data/Testimonial";

export default function Testimonial() {
  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow]}
        className="mySwiper w-full md:w-[80%] mb-10"
      >
        {testimonialData.map((item, index) => (
          <SwiperSlide
            className="Testimonial bg-white p-5 rounded-lg shadow-lg"
            key={index}
          >
            <div className="bg-white p-5 rounded-lg shadow-lg h-full">
              <div className="flex flex-col justify-between h-full">
                <div className="flex justify-center items-center">
                  <div className="w-20 h-20">
                    <img
                      src={item.img}
                      alt={item.Name}
                      className="aspect-square rounded-full"
                    />
                  </div>
                </div>
                <div>
                  <h1 className="text-xl font-bold">{item.Name}</h1>
                  <p className="text-gray-600">{item.describe}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
