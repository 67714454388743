const MenuTypes = [
  { id: 1, title: "Breakfast" },
  { id: 2, title: "Cafe" },
  { id: 3, title: "Snacks" },
];

const Menuheadings = [
  // Breakfast menu
  { id: 1, menuTypeId: 1, title: "BreakfastSet", value: "breakfast" },
  { id: 2, menuTypeId: 1, title: "Toast", value: "toast" },
  { id: 3, menuTypeId: 1, title: "Muesli", value: "muesli" },
  { id: 4, menuTypeId: 1, title: "Additional", value: "additional" },
  { id: 20, menuTypeId: 1, title: "Porridge", value: "porridge" },

  // Cafe menu
  { id: 5, menuTypeId: 2, title: "Hot Beverages", value: "hotbeverages" },
  { id: 6, menuTypeId: 2, title: "Cold Beverages", value: "coldbeverages" },
  { id: 7, menuTypeId: 2, title: "Smoothie", value: "smoothie" },
  { id: 8, menuTypeId: 2, title: "Shake", value: "shake" },
  { id: 9, menuTypeId: 2, title: "Tea", value: "tea" },
  { id: 10, menuTypeId: 2, title: "Lassi", value: "lassi" },
  { id: 11, menuTypeId: 2, title: "Cold Drinks", value: "colddrinks" },
  { id: 12, menuTypeId: 2, title: "Hookah", value: "hookah" },
  { id: 13, menuTypeId: 2, title: "Additional", value: "additional" },

  // Snacks menu
  { id: 14, menuTypeId: 3, title: "Momo", value: "momo" },
  { id: 15, menuTypeId: 3, title: "Burger", value: "burger" },
  { id: 16, menuTypeId: 3, title: "Pizza", value: "pizza" },
  { id: 17, menuTypeId: 3, title: "Snacks", value: "snacks" },
  { id: 18, menuTypeId: 3, title: "Ramen", value: "ramen" },
  { id: 19, menuTypeId: 3, title: "Additional", value: "additional" },

  // add Breakfast menu porridge
];

const MenuData = [
  // Breakfast menu
  {
    id: 1,
    menuTypes: 1,
    Menuheadings: 1,
    title: "English Breakfast",
    price: 10,
    description:
      "Bread , Egg , Sausage , (Bacon or Ham) , Baked Beans , Hash Brown, Fresh Fruit, Fresh Juice, Pancake, Grilled Tomato  & (Tea or Coffee).",

  },
  {
    id: 2,
    menuTypes: 1,
    Menuheadings: 1,
    title: "Basic Breakfast",
    price: 8,
    description:
      "Bread , Egg , Hash Brown , Grilled Tomato , Fresh Fruit, Fresh Juice & (Tea or Coffee).",

  },
  {
    id: 3,
    menuTypes: 1,
    Menuheadings: 1,
    title: "American Breakfast",
    price: 9,
    description:
      "Bread , Egg , Hash Brown, (Sausage or Bacon or Ham), Fresh Fruit, Fresh Juice, Pancake, Grilled Tomato & (Tea or Coffee).",
  },
  {
    id: 4,
    menuTypes: 1,
    Menuheadings: 1,
    title: "Easy Breakfast",
    price: 7,
    description: "Bread, Egg, Fresh Fruit, Fresh Juice & (Tea or Coffee).",
  },
  {
    id: 5,
    menuTypes: 1,
    Menuheadings: 2,
    title: "French Toast",
    price: 4,
    description: "Slice of Toast with Fruits Topping.",
  },
  {
    id: 6,
    menuTypes: 1,
    Menuheadings: 2,
    title: "Plain Toast",
    price: 3,
    description: "Toast with Butter, (Jam or Peanut Butter or Nutella).",
  },
  {
    id: 7,
    menuTypes: 1,
    Menuheadings: 2,
    title: "Toast & Egg",
    price: 5,
    description: "Toast with Butter, (Jam or Peanut Butter or Nutella) & Egg.",
  },
  {
    id: 8,
    menuTypes: 1,
    Menuheadings: 3,
    title: "Muesli Bowl",
    price: 6,
    description: "Muesli, Yogurt, Dry Nuts & Fresh Fruit.",
  },
  {
    id: 9,
    menuTypes: 1,
    Menuheadings: 3,
    title: "Fruit Bowl",
    price: 5,
    description: "Mix Seasonal Fruit.",
  },
  {
    id: 10,
    menuTypes: 1,
    Menuheadings: 3,
    title: "Pancake",
    price: 7,
    description: "Pancake With Honey & Pancake Syrup topped with Some Fruits.",
  },
  {
    id: 11,
    menuTypes: 1,
    Menuheadings: 20,
    title: "Plain Porridge",
    price: 4,
    description: "Plain",
  },
  {
    id: 12,
    menuTypes: 1,
    Menuheadings: 20,
    title: "Mix Fruit & Nuts Porridge",
    price: 5,
    description: "Sweet",
  },

  // breakfast additional
  {
    id: 13,
    menuTypes: 1,
    Menuheadings: 4,
    title: "Hash Brown",
    price: 2,
    description: "Hash Brown",
  },
  {
    id: 14,
    menuTypes: 1,
    Menuheadings: 4,
    title: "Sausage",
    price: 3,
    description: "Sausage",
  },
  {
    id: 15,
    menuTypes: 1,
    Menuheadings: 4,
    title: "Bacon/Ham",
    price: 4,
    description: "Bacon/Ham",
  },
  {
    id: 16,
    menuTypes: 1,
    Menuheadings: 4,
    title: "Egg",
    price: 1,
    description: "Egg",
  },

  /// cafe menu
  {
    id: 17,
    menuTypes: 2,
    Menuheadings: 5,
    title: "Espresso",
    price: 5.5,
    description: "Espresso",
  },
  {
    id: 18,
    menuTypes: 2,
    Menuheadings: 5,
    title: "Double Espresso",
    price: 6.5,
    description: "Double Espresso",
  },
  {
    id: 19,
    menuTypes: 2,
    Menuheadings: 5,
    title: "Macchiato",
    price: 7.5,
    description: "Macchiato",
  },
  {
    id: 20,
    menuTypes: 2,
    Menuheadings: 5,
    title: "Americano",
    price: 8.5,
    description: "Americano",
  },
  {
    id: 21,
    menuTypes: 2,
    Menuheadings: 5,
    title: "Cappuccino",
    price: 9.5,
    description: "Cappuccino",
  },
  {
    id: 22,
    menuTypes: 2,
    Menuheadings: 5,
    title: "Latte",
    price: 10.5,
    description: "Latte",
  },
  {
    id: 23,
    menuTypes: 2,
    Menuheadings: 5,
    title: "Latte (Honey | Caramel | Flavour)",
    price: 11.5,
    description: "Latte (Honey | Caramel | Flavour)",
  },
  {
    id: 24,
    menuTypes: 2,
    Menuheadings: 5,
    title: "Latte Macchiato",
    price: 12.5,
    description: "Latte Macchiato",
  },
  {
    id: 25,
    menuTypes: 2,
    Menuheadings: 5,
    title: "Café Mocha",
    price: 13.5,
    description: "Café Mocha",
  },
  {
    id: 26,
    menuTypes: 2,
    Menuheadings: 5,
    title: "Mocha Madness",
    price: 14.5,
    description: "Mocha Madness",
  },
  {
    id: 27,
    menuTypes: 2,
    Menuheadings: 5,
    title: "Hot Chocolate",
    price: 15.5,
    description: "Hot Chocolate",
  },
  {
    id: 28,
    menuTypes: 2,
    Menuheadings: 6,
    title: "Iced Americano",
    price: 2.5,
    description: "Iced Americano",
  },
  {
    id: 29,
    menuTypes: 2,
    Menuheadings: 6,
    title: "Iced Cappuccino",
    price: 3.5,
    description: "Iced Cappuccino",
  },
  {
    id: 30,
    menuTypes: 2,
    Menuheadings: 6,
    title: "Iced Latte",
    price: 4.5,
    description: "Iced Latte",
  },
  {
    id: 31,
    menuTypes: 2,
    Menuheadings: 6,
    title: "Iced Mocha",
    price: 5.5,
    description: "Iced Mocha",
  },
  {
    id: 32,
    menuTypes: 2,
    Menuheadings: 6,
    title: "Iced Chocolate",
    price: 6.5,
    description: "Iced Chocolate",
  },
  {
    id: 33,
    menuTypes: 2,
    Menuheadings: 6,
    title: "Iced Tea",
    price: 7.5,
    description: "Iced Tea",
  },
  {
    id: 34,
    menuTypes: 2,
    Menuheadings: 6,
    title: "Iced Coffee",
    price: 8.5,
    description: "Iced Coffee",
  },
  {
    id: 35,
    menuTypes: 2,
    Menuheadings: 6,
    title: "Iced Latte (Honey | Caramel | Flavour)",
    price: 9.5,
    description: "Iced Latte (Honey | Caramel | Flavour)",
  },
  {
    id: 36,
    menuTypes: 2,
    Menuheadings: 6,
    title: "Iced Mocha Madness",
    price: 10.5,
    description: "Iced Mocha Madness",
  },
  {
    id: 37,
    menuTypes: 2,
    Menuheadings: 6,
    title: "Iced Chocolate Madness",
    price: 11.5,
    description: "Iced Chocolate Madness",
  },

  // smoothie
  {
    id: 38,
    menuTypes: 2,
    Menuheadings: 7,
    title: "Mango Smoothie",
    price: 5.5,
    description: "Mango Smoothie",
  },
  {
    id: 39,
    menuTypes: 2,
    Menuheadings: 7,
    title: "Blueberry Smoothie",
    price: 6.5,
    description: "Blueberry Smoothie",
  },

  // shake
  {
    id: 40,
    menuTypes: 2,
    Menuheadings: 8,
    title: "Mango Shake",
    price: 5.5,
    description: "Mango Shake",
  },
  {
    id: 41,
    menuTypes: 2,
    Menuheadings: 8,
    title: "Blueberry Shake",
    price: 6.5,
    description: "Blueberry Shake",
  },
  {
    id: 42,
    menuTypes: 2,
    Menuheadings: 8,
    title: "Strawberry Shake",
    price: 7.5,
    description: "Strawberry Shake",
  },
  {
    id: 43,
    menuTypes: 2,
    Menuheadings: 8,
    title: "Chocolate Shake",
    price: 8.5,
    description: "Chocolate Shake",
  },
  {
    id: 44,
    menuTypes: 2,
    Menuheadings: 8,
    title: "Vanilla Shake",
    price: 9.5,
    description: "Vanilla Shake",
  },
  {
    id: 45,
    menuTypes: 2,
    Menuheadings: 8,
    title: "Oreo Shake",
    price: 10.5,
    description: "Oreo Shake",
  },
  {
    id: 46,
    menuTypes: 2,
    Menuheadings: 8,
    title: "Kitkat Shake",
    price: 10.5,
    description: "Kitkat Shake",
  },

  // tea
  {
    id: 47,
    menuTypes: 2,
    Menuheadings: 9,
    title: "Black Tea",
    price: 2.5,
    description: "Black Tea",
  },
  {
    id: 48,
    menuTypes: 2,
    Menuheadings: 9,
    title: "Milk/Masala Tea",
    price: 3.5,
    description: "Milk/Masala Tea",
  },
  {
    id: 49,
    menuTypes: 2,
    Menuheadings: 9,
    title: "Hot Lemon with Honey",
    price: 4.5,
    description: "Hot Lemon with Honey",
  },
  {
    id: 50,
    menuTypes: 2,
    Menuheadings: 9,
    title: "Herbal Tea",
    price: 5.5,
    description:
      "Herbal Tea (Chomrong Himalayan Tea, Green Tea, Mint Tea, Chamomile Tea)",
  },

  // lassi
  {
    id: 51,
    menuTypes: 2,
    Menuheadings: 10,
    title: "Plain Lassi",
    price: 2.5,
    description: "Plain Lassi",
  },
  {
    id: 52,
    menuTypes: 2,
    Menuheadings: 10,
    title: "Banana Lassi",
    price: 3.5,
    description: "Banana Lassi",
  },
  {
    id: 53,
    menuTypes: 2,
    Menuheadings: 10,
    title: "Chocolate Lassi",
    price: 4.5,
    description: "Chocolate Lassi",
  },

  // hookah
  {
    id: 54,
    menuTypes: 2,
    Menuheadings: 12,
    title: "Mint Hookah",
    price: 2.5,
    description: "Mint Hookah",
  },
  {
    id: 55,
    menuTypes: 2,
    Menuheadings: 12,
    title: "Double Apple Hookah",
    price: 3.5,
    description: "Double Apple Hookah",
  },
  {
    id: 56,
    menuTypes: 2,
    Menuheadings: 12,
    title: "Extra Coil Hookah",
    price: 4.5,
    description: "Extra Coil Hookah",
  },

  // snacks
  // Momo items
  {
    id: 51,
    menuTypes: 3,
    Menuheadings: 14,
    title: "Veg steam Momo",
    price: 5,
    description: "Veg steam Momo",
  },
  {
    id: 52,
    menuTypes: 3,
    Menuheadings: 14,
    title: "Veg fried Momo",
    price: 6,
    description: "Veg fried Momo",
  },
  {
    id: 53,
    menuTypes: 3,
    Menuheadings: 14,
    title: "Chicken steam Momo",
    price: 7,
    description: "Chicken steam Momo",
  },
  {
    id: 54,
    menuTypes: 3,
    Menuheadings: 14,
    title: "Chicken fried Momo",
    price: 8,
    description: "Chicken fried Momo",
  },
  {
    id: 55,
    menuTypes: 3,
    Menuheadings: 14,
    title: "Jhol Momo",
    price: 9,
    description: "Jhol Momo",
  },
  {
    id: 56,
    menuTypes: 3,
    Menuheadings: 14,
    title: "Chilly Momo",
    price: 10,
    description: "Chilly Momo",
  },

  // Sandwiches items
  {
    id: 57,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Veg Sandwich",
    price: 5,
    description: "Veg Sandwich",
  },
  {
    id: 58,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Chicken Sandwich",
    price: 6,
    description: "Chicken Sandwich",
  },
  {
    id: 59,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Tuna Sandwich",
    price: 7,
    description: "Tuna Sandwich",
  },

  // Burger items
  {
    id: 60,
    menuTypes: 3,
    Menuheadings: 15,
    title: "Grill Chicken Burger",
    price: 5,
    description: "Grill Chicken Burger",
  },
  {
    id: 61,
    menuTypes: 3,
    Menuheadings: 15,
    title: "Crispy Chicken Burger",
    price: 6,
    description: "Crispy Chicken Burger",
  },
  {
    id: 62,
    menuTypes: 3,
    Menuheadings: 15,
    title: "Mix Veg Burger",
    price: 7,
    description: "Mix Veg Burger",
  },

  // Pizza items
  {
    id: 63,
    menuTypes: 3,
    Menuheadings: 16,
    title: "Veg Mix Pizza",
    price: "400/500",
    description: "Veg Mix Pizza",
  },
  {
    id: 64,
    menuTypes: 3,
    Menuheadings: 16,
    title: "Corn Cheese Pizza",
    price: "400/500",
    description: "Corn Cheese Pizza",
  },
  {
    id: 65,
    menuTypes: 3,
    Menuheadings: 16,
    title: "Chicken Pizza",
    price: "400/500",
    description: "Chicken Pizza",
  },
  {
    id: 66,
    menuTypes: 3,
    Menuheadings: 16,
    title: "Tomato Mushroom Pizza",
    price: "400/500",
    description: "Tomato Mushroom Pizza",
  },
  {
    id: 67,
    menuTypes: 3,
    Menuheadings: 16,
    title: "Sausage Pizza",
    price: "400/500",
    description: "Sausage Pizza",
  },
  {
    id: 68,
    menuTypes: 3,
    Menuheadings: 16,
    title: "Mix Non-Veg Pizza",
    price: "400/500",
    description: "Mix Non-Veg Pizza",
  },
  {
    id: 69,
    menuTypes: 3,
    Menuheadings: 16,
    title: "Meat Lover Pizza",
    price: "400/500",
    description: "Meat Lover Pizza",
  },

  // Snacks items
  {
    id: 70,
    menuTypes: 3,
    Menuheadings: 17,
    title: "French Fry",
    price: 5,
    description: "French Fry",
  },
  {
    id: 71,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Cheese Corn Dog",
    price: 6,
    description: "Cheese Corn Dog",
  },
  {
    id: 72,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Chips Chilly",
    price: 7,
    description: "Chips Chilly",
  },
  {
    id: 73,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Veg Pakoda",
    price: 8,
    description: "Veg Pakoda",
  },
  {
    id: 74,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Noodle Sadeko",
    price: 9,
    description: "Noodle Sadeko",
  },
  {
    id: 75,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Peanut Sadeko",
    price: 10,
    description: "Peanut Sadeko",
  },
  {
    id: 76,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Chicken Sadeko",
    price: 11,
    description: "Chicken Sadeko",
  },
  {
    id: 77,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Chicken Chilly",
    price: 12,
    description: "Chicken Chilly",
  },
  {
    id: 78,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Buffalo Wings",
    price: 13,
    description: "Buffalo Wings",
  },
  {
    id: 79,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Chicken Lollipop",
    price: 14,
    description: "Chicken Lollipop",
  },
  {
    id: 80,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Chicken Drum Stick",
    price: 15,
    description: "Chicken Drum Stick",
  },
  {
    id: 81,
    menuTypes: 3,
    Menuheadings: 17,
    title: "Fried Rice ( Veg / Egg / Chicken )",
    price: 16,
    description: "Fried Rice ( Veg / Egg / Chicken )",
  },

  // Ramen items
  {
    id: 82,
    menuTypes: 3,
    Menuheadings: 18,
    title: "Ramen 1x",
    price: 5,
    description: "Ramen 1x spicy with egg and sausage",
  },
  {
    id: 83,
    menuTypes: 3,
    Menuheadings: 18,
    title: "Ramen 2x",
    price: 6,
    description: "Ramen 2x spicy with egg and sausage",
  },

  //// cold drinks
  {
    id: 84,
    menuTypes: 2,
    Menuheadings: 11,
    title: "Coca Cola",
    price: 2.5,
    description: "Coca Cola",
  },
  {
    id: 85,
    menuTypes: 2,
    Menuheadings: 11,
    title: "Fanta",
    price: 3.5,
    description: "Fanta",
  },
  {
    id: 86,
    menuTypes: 2,
    Menuheadings: 11,
    title: "Sprite",
    price: 4.5,
    description: "Sprite",
  },
  {
    id: 87,
    menuTypes: 2,
    Menuheadings: 11,
    title: "Pepsi",
    price: 5.5,
    description: "Pepsi",
  },
  {
    id: 88,
    menuTypes: 2,
    Menuheadings: 11,
    title: "Mountain Dew",
    price: 6.5,
    description: "Mountain Dew",
  },
  {
    id: 89,
    menuTypes: 2,
    Menuheadings: 11,
    title: "Red Bull",
    price: 7.5,
    description: "Red Bull",
  },
  {
    id: 90,
    menuTypes: 2,
    Menuheadings: 11,
    title: "Tonic Water",
    price: 8.5,
    description: "Tonic Water",
  },
  {
    id: 91,
    menuTypes: 2,
    Menuheadings: 11,
    title: "Soda Water",
    price: 9.5,
    description: "Soda Water",
  },
  {
    id: 92,
    menuTypes: 2,
    Menuheadings: 11,
    title: "Mineral Water",
    price: 10.5,
    description: "Mineral Water",
  },

  // cafe additional
  {
    id: 93,
    menuTypes: 2,
    Menuheadings: 13,
    title: "Extra Shot",
    price: 2,
    description: "Extra Shot",
  },
  {
    id: 94,
    menuTypes: 2,
    Menuheadings: 13,
    title: "Whipped Cream",
    price: 3,
    description: "Whipped Cream",
  },
  {
    id: 95,
    menuTypes: 2,
    Menuheadings: 13,
    title: "Ice Cream",
    price: 4,
    description: "Ice Cream",
  },
  {
    id: 96,
    menuTypes: 2,
    Menuheadings: 13,
    title: "Chocolate Syrup",
    price: 5,
    description: "Chocolate Syrup",
  },
  {
    id: 97,
    menuTypes: 2,
    Menuheadings: 13,
    title: "Caramel Syrup",
    price: 6,
    description: "Caramel Syrup",
  },
  {
    id: 98,
    menuTypes: 2,
    Menuheadings: 13,
    title: "Vanilla Syrup",
    price: 7,
    description: "Vanilla Syrup",
  },
  {
    id: 99,
    menuTypes: 2,
    Menuheadings: 13,
    title: "Honey",
    price: 8,
    description: "Honey",
  },
  {
    id: 100,
    menuTypes: 2,
    Menuheadings: 13,
    title: "Cinnamon Powder",
    price: 9,
    description: "Cinnamon Powder",
  },
  {
    id: 101,
    menuTypes: 2,
    Menuheadings: 13,
    title: "Chocolate Powder",
    price: 10,
    description: "Chocolate Powder",
  },
  {
    id: 102,
    menuTypes: 2,
    Menuheadings: 13,
    title: "Caramel Powder",
    price: 11,
    description: "Caramel Powder",
  },
  {
    id: 103,
    menuTypes: 2,
    Menuheadings: 13,
    title: "Vanilla Powder",
    price: 12,
    description: "Vanilla Powder",
  },

  // snacks additional
  {
    id: 104,
    menuTypes: 3,
    Menuheadings: 19,
    title: "Extra Cheese",
    price: 2,
    description: "Extra Cheese",
  },
  {
    id: 105,
    menuTypes: 3,
    Menuheadings: 19,
    title: "Extra Sauce",
    price: 3,
    description: "Extra Sauce",
  },
  {
    id: 106,
    menuTypes: 3,
    Menuheadings: 19,
    title: "Extra Patty",
    price: 4,
    description: "Extra Patty",
  },
  {
    id: 107,
    menuTypes: 3,
    Menuheadings: 19,
    title: "Extra Fries",
    price: 5,
    description: "Extra Fries",
  },
  {
    id: 108,
    menuTypes: 3,
    Menuheadings: 19,
    title: "Extra Chicken",
    price: 6,
    description: "Extra Chicken",
  },
  {
    id: 109,
    menuTypes: 3,
    Menuheadings: 19,
    title: "Extra Veg",
    price: 7,
    description: "Extra Veg",
  },
  {
    id: 110,
    menuTypes: 3,
    Menuheadings: 19,
    title: "Extra Sauce",
    price: 8,
    description: "Extra Sauce",
  },
  {
    id: 111,
    menuTypes: 3,
    Menuheadings: 19,
    title: "Extra Cheese",
    price: 9,
    description: "Extra Cheese",
  },
  {
    id: 112,
    menuTypes: 3,
    Menuheadings: 19,
    title: "Extra Sauce",
    price: 10,
    description: "Extra Sauce",
  },
  {
    id: 113,
    menuTypes: 3,
    Menuheadings: 19,
    title: "Extra Patty",
    price: 11,
    description: "Extra Patty",
  },
  {
    id: 114,
    menuTypes: 3,
    Menuheadings: 19,
    title: "Extra Fries",
    price: 12,
    description: "Extra Fries",
  },
];

export { MenuTypes, Menuheadings, MenuData };
