import React from "react";
import Banner from "../component/slider/slider.jsx";
import Quotations from "../component/quotations/quotations.jsx";
import Menu from "../component/menu/menu.jsx";
import About from "../component/index/about.jsx";
import BestProduct from "../component/index/bestProduct.jsx";

export default function Home() {
  return (
    <div className="flex justify-center flex-col pb-5">
      {/* menu */}
      <div className="flex justify-center flex-col mt-5">
        <Menu />
      </div>
    </div>
  );
}
