const Testimonial=[
    {
        Id:1,
        Name:"Ayush Gurung",
        img :"https://swiperjs.com/demos/images/nature-1.jpg",
        describe:"I am very happy with the service of Dozy Kooki. I have been using their service for a long time and I am very satisfied with their service. I would like to recommend Dozy Kooki to everyone.",
    },
    {
        Id:2,
        Name:"Alex Gurung",
        img :"https://swiperjs.com/demos/images/nature-2.jpg",
        describe:"There customer service is very good. I am very happy with their service. I would like to recommend Dozy Kooki to everyone.",
    },
    {
        Id:3,
        Name:"Anish Gurung",
        img :"https://swiperjs.com/demos/images/nature-3.jpg",
        describe:"Ice cream is very tasty. I am very happy with their service. I would like to recommend Dozy Kooki to everyone.",
    },
]
export default Testimonial;