import { FaInstagram } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="bg-primary text-white py-10  flex justify-center items-center">
      <div className="   w-[90%]  md:w-[75%]">
        <div className="flex flex-col md:flex-row justify-between  gap-8">
          <div className="flex flex-col gap-5 w-full">
            <h1 className="text-2xl font-bold">Contact</h1>
            <p>Phone: 061-460267 / 9826136372</p>
            <p>
              Email:
              <a
                href="mailto:
                "
              ></a>
            </p>
            <p>Address: Pokhara, Lakeside, Street - 7 </p>
          </div>
          <div className="flex flex-col gap-5 w-full">
            <h1 className="text-2xl font-bold">Opening Hours</h1>
            <p>Monday - Friday: 7am - 9pm</p>
            <p>Saturday - Sunday: 8am - 9pm</p>
          </div>
          <div className="flex flex-col gap-5 w-full top-0">
            <h1 className="text-2xl font-bold">Follow Us</h1>
            <div className="flex gap-5">
              <a href="#">
              <FaInstagram className="w-6 h-6"/>
              </a>
              <a href="#">
                <BsTwitterX className="w-6 h-6" />
              </a>
              <a href="#">
                <FaFacebookF className="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
