const text = `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil facere
commodi, labore provident similique deserunt facilis est obcaecati
eveniet laboriosam veniam explicabo perferendis expedita sequi animi
fuga sit fugiat culpa. Lorem ipsum, dolor sit amet consectetur
adipisicing elit. Nihil facere commodi, labore provident similique
deserunt facilis est obcaecati eveniet laboriosam veniam explicabo
perferendis expedita sequi animi fuga sit fugiat culpa.`;

const charLimit = 300; // Set your desired character limit here

const truncateTextByCharacters = (text, limit) => {
  if (text.length > limit) {
    return text.slice(0, limit) + "...";
  }
  return text;
};

export default function About() {
  return (
    <div className=" w-full  py-5 flex gap-5  flex-col md:flex-row">
      <div className="img h-[30vh] w-[100%]  md:h-[37vh] md:w-[200vh] ">
        <img
          src="./img/banner/banner1.jpg"
          alt=""
          className="h-full w-full rounded-xl "
        />
      </div>
      <div className=" flex flex-col justify-center gap-2 text-wrap ">
        <h1 className=" flex justify-center text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl md:justify-start">
          About Our Cafe
        </h1>
        <p className=" text-justify text-md">
          {truncateTextByCharacters(text, charLimit)}
        </p>
        {/*  explore more */}
        <div className=" flex justify-center items-center md:justify-start mt-3">
          <button className="bg-primary text-white px-3 py-1 rounded-md">
            learn more
          </button>
        </div>
      </div>
    </div>
  );
}
