import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./testimonial.css";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";

const data = [
  {
    id: 1,
    name: "Jhon Doe",
    image: "/img/testimonial/testimonial1.jpg",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, quas.Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, quas.",
  },
  {
    id: 2,
    name: "Jhon Doe",
    image: "/img/testimonial/testimonial2.jpg",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, quas.",
  },
  {
    id: 3,
    name: "Jhon Doe",
    image: "/img/testimonial/testimonial3.jpg",
    review:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, quas.",
  },
];

export default function Testimonial() {
  // Add your code here

  return (
    <div className="testimonial flex flex-col  justify-center items-center  w-full">
      <h1 className="text-2xl font-bold mt-8 md:text-4xl w-[80%] text-center">
        What Our Customers Say
      </h1>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[EffectCoverflow]}
        className="mySwiper"
      >
        {data.map((item) => (
          <SwiperSlide key={item.id} className="w-[50vh] border p-5 h-auto">
            <div className="flex flex-col justify-center items-center gap-5">
              <div className=" h-28 md:h-40 aspect-square ">
                <img
                  src={item.image}
                  alt={item.name}
                  className=" w-full h-full rounded-full object-cover"
                />
              </div>
              <h1 className="text-xl font-bold">{item.name}</h1>
              <p className="text-center text-sm ">{item.review}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="">
        <div className="flex justify-center items-center">
          <div className="flex flex-row gap-5"></div>
        </div>
      </div>
    </div>
  );
}
