import React, { useState,useEffect } from "react";

export default function MenuSlider({ sliderData, active, setActive }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(sliderData.find(item => item.id === active) || sliderData[0]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownItemClick = (item) => {
    setSelectedDropdownItem(item);
    setActive(item.id);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    setSelectedDropdownItem(sliderData.find(item => item.id === active) || sliderData[0]);
  }, [active, sliderData]);

  return (
    <div className="w-[90%] mx-auto mt-2">
      {/* Slider for larger screens */}
      <div
        className=" flex-row overflow-auto gap-4 no-scrollbar md:justify-center hidden md:flex"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {sliderData.map((item) => (
          <button
            key={item.id}
            className="flex-shrink-0"
            onClick={() => setActive(item.id)}
          >
            <div
              className={`flex justify-center items-center border rounded-xl px-3 py-2 ${
                active === item.id
                  ? "bg-primary text-white"
                  : "bg-white text-black"
              }`}
            >
              <h1 className="text-sm font-bold">{item.title}</h1>
            </div>
          </button>
        ))}
      </div>

      {/* Custom Dropdown for mobile devices */}
      <div className="flex justify-center items-center mt-2 md:hidden">
        <div className="relative w-full">
          <button
            onClick={toggleDropdown}
            className="bg-white border border-gray-300 rounded-xl px-3 py-2 flex items-center justify-between w-full"
          >
            <span className="text-sm font-bold">{selectedDropdownItem.title}</span>
            <svg
              className={`w-5 h-5 transition-transform ${isDropdownOpen ? "rotate-180" : "rotate-0"}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>

          {isDropdownOpen && (
            <div className="absolute z-10 bg-white border border-gray-300 rounded-xl mt-2 w-full shadow-lg">
              {sliderData.map((item) => (
                <button
                  key={item.id}
                  onClick={() => handleDropdownItemClick(item)}
                  className="w-full text-left px-4 py-2 hover:bg-gray-100 rounded-xl"
                >
                  {item.title}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
